import React from "react";
import { graphql } from "gatsby";
import FlexiblePage from "../components/reusable/FlexiblePage";
import Seo from "../components/global/Seo";

export default function FlexiblePages(props) {
  console.log(props);
  return (
    <div className="sticky-wrapper">
      <Seo seo={props.data.page.seo} />
      {props.data.page !== null ? (
        <FlexiblePage
          post={false}
          path={props.path}
          data={props.data.page}
          isTaproom={props.pageContext.id === "cG9zdDo4MDc=" ? true : false}
          taproomFields={props.data.taproom}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export const FlexibleQuery = graphql`
  query FlexiblePagesQuery($id: String) {
    taproom: wpPage(title: { eq: "Taproom" }) {
      acfTaproomFields {
        upcomingEvents {
          buttonLink {
            ... on WpPost {
              id
              uri
            }
          }
          buttonText
          dropdownText
        }
        upcomingFood {
          buttonLink {
            ... on WpPost {
              id
              uri
            }
          }
          buttonText
          dropdownText
        }
        upcomingMusic {
          buttonLink {
            ... on WpPost {
              id
              uri
            }
          }
          buttonText
          dropdownText
        }
      }
    }
    page: wpPage(id: { eq: $id }) {
      title
      acfCustomThumbnail {
        customThumbnail {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      seo {
        metaDesc
        twitterTitle
        twitterDescription
        opengraphDescription
        opengraphTitle
        title
        opengraphImage {
          publicUrl
        }
        twitterImage {
          publicUrl
        }
      }
      acfFlexContent {
        contentBlocks {
          ... on WpPage_Acfflexcontent_ContentBlocks_TextBlock {
            fieldGroupName
            text
          }
          ... on WpPage_Acfflexcontent_ContentBlocks_ImageGallery {
            fieldGroupName
            imageGallery {
              caption
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on WpPage_Acfflexcontent_ContentBlocks_Video {
            fieldGroupName
            videoEmbedCode
          }
          ... on WpPage_Acfflexcontent_ContentBlocks_EventbriteTicketCheckout {
            uniqueEventId
            fieldGroupName
          }
          ... on WpPage_Acfflexcontent_ContentBlocks_Image2ImageColumns {
            fieldGroupName
            image1 {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            image2 {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          ... on WpPage_Acfflexcontent_ContentBlocks_ImageFullWidth {
            fieldGroupName
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`;
